<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col cols="4">
              <label>Survey Description</label>
              <b-form-input v-model="search.description"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Date Created From</label>
              <b-form-datepicker v-model="search.dateCreatedFrom"></b-form-datepicker>
            </b-col>
            <b-col cols="4">
              <label>Date Created To</label>
              <b-form-datepicker v-model="search.dateCreatedTo"></b-form-datepicker>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col cols="4">
              <label>Category</label>
              <b-form-select></b-form-select>
            </b-col>
          </b-row> -->
          <hr class="mx-3" />
          <b-row>
            <b-col class="text-right">
              <b-button variant="primary" squared class="text-right" @click="exportDocument()">Export to .xls</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="fetchingData === true">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-center">
              <h6 class="font-weight-bold"> Fetching data. This might take a while</h6>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-center">
              <h6 class="font-weight-bold">Your download will begin shortly, if it doesn't please click here:</h6>
                <download-csv
                    :data   = "jsonData"
                    :name    = fileName>
                  <b-button variant="primary" squared class="text-right" @click="downloadResponse">Export to .xls</b-button>
                </download-csv>
            </b-col>
          </b-row>
          <b-row>
            <b-col></b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data:() => ({
    search: {
      description: null,
      dateCreatedFrom: null,
      dateCreatedTo: null
    },
    jsonData: [],
    fetchingData: false
  }),
  methods:{
    ...mapActions(['downloadSurveyResponseReport']),
        ...mapActions('notificationService', ['addWarningMessage']),
    exportDocument(){
      this.fetchingData = true
      let request = []
      if(this.search.description !== null){
          request.push({"key":"description","value": this.search.description})
      }
      if(this.search.dateCreatedFrom !== null){
          request.push({"key":"dateCreatedFrom","value": this.search.dateCreatedFrom})
      }
      if(this.search.dateCreatedTo !== null){
          request.push({"key":"dateCreatedTo","value": this.search.dateCreatedTo})
      }

      this.$store.commit('setDownloadSurveyRequest', request)
      this.downloadSurveyResponseReport()
      .then((res) => {
        this.fetchingData = false

        if (res.data !== '') {
          const linkSource = `data:${res.data.contentType};base64,${res.data.base64Data}`;
          const downloadLink = document.createElement("a");

          downloadLink.href = linkSource;
          downloadLink.download = res.data.fileName;
          downloadLink.click();
        }
        else{
          this.addWarningMessage('No data available to download')
        }
      })
      .catch(() => {
        this.fetchingData = false
      })
    },
    downloadResponse(){
      if (this.jsonData.length === 0) {
        this.addWarningMessage('There is no data to be downloaded')
      } else {
        this.addSuccessMessage('Your content has been downloaded ')
      }
    }
  }
}
</script>

<style scoped>

</style>